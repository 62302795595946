// Import React State
import { useState , useRef} from "react";

// Import React Icons
import { FiUser, FiMail, FiPhone, FiMessageSquare } from "react-icons/fi";

// Import Email Js 
import emailjs from '@emailjs/browser';

// Contact Form Component
const ContactForm = () => {
  const [formData, setFormData] = useState({
    from_name: "",
    from_lastname: "",
    from_email: "",
    from_telephone: "",
    message: ""
  });

  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  // Use form reference
  const form = useRef();

  const validateField = (name, value) => {
    switch (name) {
      case "from_name":
      case "from_lastname":
        return value.trim() ? "" : "Este campo es obligatorio";
      case "from_email":
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? "" : "Formato de correo electrónico inválido";
      case "from_telephone":
        return /^[0-9]{8,9}$/.test(value) ? "" : "Ingrese un numero de telefono";
      case "message":
        return value.trim().length >= 10 ? "" : "El mensaje debe tener al menos 10 caracteres";
      default:
        return "";
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: validateField(name, value) }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      const error = validateField(key, formData[key]);
      if (error) newErrors[key] = error;
    });

    if (Object.keys(newErrors).length === 0) {
      emailjs
            .sendForm('service_kdko3x7', 'template_2aryzib', form.current, {
                publicKey: 'yY5hfnVM1CmbWx_Or'
            })
            .then(
                () => {
                  setSubmitted(true);

                  setFormData({
                    from_name: "",
                    from_lastname: "",
                    from_email: "",
                    from_telephone: "",
                    message: ""
                  });

                  setTimeout(() => setSubmitted(false), 3000);
                },
                (error) => {
                    console.log(error);
                    console.log('Error al enviar mensaje');
                },
            );
    } else {
      setErrors(newErrors);
    }
  };

  // Updated isFormValid to only check for non-empty values
  const isFormValid = Object.values(formData).every(value => value.trim() !== "");

  return (
    <div id="contact-form" className="min-h-screen flex items-center justify-center px-4 py-12">
      <div className="max-w-4xl w-full space-y-8 bg-white p-6 md:p-10 rounded-2xl shadow-lg">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-blue-900 mb-2 uppercase">Contáctenos</h2>
        </div>

        {submitted && (
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative animate-fade-in" role="alert">
            <p>¡Gracias por tu mensaje! Te responderemos pronto.</p>
          </div>
        )}

        <form onSubmit={handleSubmit} ref={form} className="mt-8 space-y-6" noValidate>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="firstName" className="flex items-center text-sm font-medium text-gray-700 mb-1">
                <FiUser className="mr-2" /> Nombre
              </label>
              <input
                id="firstName"
                name="from_name"
                type="text"
                required
                className={`appearance-none rounded-lg relative block w-full px-4 py-3 border ${errors.firstName ? "border-red-500" : "border-gray-300"} placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200`}
                value={formData.from_name}
                onChange={handleChange}
                aria-label="Nombre"
                aria-invalid={!!errors.firstName}
                aria-describedby={errors.firstName ? "firstName-error" : undefined}
              />
              {errors.firstName && (
                <p className="mt-2 text-sm text-red-600" id="firstName-error">{errors.firstName}</p>
              )}
            </div>

            <div>
              <label htmlFor="lastName" className="flex items-center text-sm font-medium text-gray-700 mb-1">
                <FiUser className="mr-2" /> Apellido
              </label>
              <input
                id="lastName"
                name="from_lastname"
                type="text"
                required
                className={`appearance-none rounded-lg relative block w-full px-4 py-3 border ${errors.lastName ? "border-red-500" : "border-gray-300"} placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200`}
                value={formData.from_lastname}
                onChange={handleChange}
                aria-label="Apellido"
                aria-invalid={!!errors.lastName}
                aria-describedby={errors.lastName ? "lastName-error" : undefined}
              />
              {errors.lastName && (
                <p className="mt-2 text-sm text-red-600" id="lastName-error">{errors.lastName}</p>
              )}
            </div>

            <div>
              <label htmlFor="email" className="flex items-center text-sm font-medium text-gray-700 mb-1">
                <FiMail className="mr-2" /> Correo Electrónico
              </label>
              <input
                id="email"
                name="from_email"
                type="email"
                required
                className={`appearance-none rounded-lg relative block w-full px-4 py-3 border ${errors.email ? "border-red-500" : "border-gray-300"} placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200`}
                value={formData.from_email}
                onChange={handleChange}
                aria-label="Correo Electrónico"
                aria-invalid={!!errors.email}
                aria-describedby={errors.email ? "email-error" : undefined}
              />
              {errors.email && (
                <p className="mt-2 text-sm text-red-600" id="email-error">{errors.email}</p>
              )}
            </div>

            <div>
              <label htmlFor="phone" className="flex items-center text-sm font-medium text-gray-700 mb-1">
                <FiPhone className="mr-2" /> Teléfono
              </label>
              <input
                id="phone"
                name="from_telephone"
                type="tel"
                required
                className={`appearance-none rounded-lg relative block w-full px-4 py-3 border ${errors.phone ? "border-red-500" : "border-gray-300"} placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200`}
                value={formData.from_telephone}
                onChange={handleChange}
                aria-label="Teléfono"
                aria-invalid={!!errors.phone}
                aria-describedby={errors.phone ? "phone-error" : undefined}
              />
              {errors.phone && (
                <p className="mt-2 text-sm text-red-600" id="phone-error">{errors.phone}</p>
              )}
            </div>
          </div>

          <div>
            <label htmlFor="message" className="flex items-center text-sm font-medium text-gray-700 mb-1">
              <FiMessageSquare className="mr-2" /> Mensaje
            </label>
            <textarea
              id="message"
              name="message"
              required
              rows="4"
              className={`appearance-none rounded-lg relative block w-full px-4 py-3 border ${errors.message ? "border-red-500" : "border-gray-300"} placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200`}
              value={formData.message}
              onChange={handleChange}
              aria-label="Mensaje"
              aria-invalid={!!errors.message}
              aria-describedby={errors.message ? "message-error" : undefined}
            />
            {errors.message && (
              <p className="mt-2 text-sm text-red-600" id="message-error">{errors.message}</p>
            )}
          </div>

          <div>
            <button
              type="submit"
              disabled={!isFormValid}
              className={`group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-lg text-white ${isFormValid ? "bg-blue-900 hover:bg-blue-950" : "bg-blue-200 cursor-not-allowed"} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-200`}
              aria-label="Enviar formulario"
            >
              Enviar Mensaje
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

// Export ContactForm Component
export default ContactForm;