// Import React
import React from "react";

// Import Logo Camino Ventura
import logoCaminoVentura from '../assets/images/logo-camino-ventura.svg';
// Import React Icons
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";

const Footer = () => {
  const socialLinks = [
    { icon: <FaFacebook />, url: "https://facebook.com", label: "Facebook" },
    { icon: <FaTwitter />, url: "https://twitter.com", label: "Twitter" },
    { icon: <FaInstagram />, url: "https://instagram.com", label: "Instagram" },
    { icon: <FaLinkedin />, url: "https://linkedin.com", label: "LinkedIn" }
  ];

  return (
    <footer className="text-white py-20 px-4 relative footer">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Company Logo Section */}
          <div className="flex flex-col items-center md:items-start">
            <div className="w-48 h-12 rounded flex items-center justify-center mb-4" 
                 role="img" 
                 aria-label="Company Logo">

                <img src={logoCaminoVentura} alt="Camino Ventura"/>
            </div>

            <br />
            
            <p className="text-white text-sm text-center md:text-left">
              Transformamos ideas en realidad.
            </p>
          </div>

          {/* Contact Information */}
          <div className="flex flex-col items-center md:items-start">
            <h3 className="text-xl font-bold mb-4" role="heading" aria-level="2">Contacto</h3>
            <div className="space-y-3">
              <a
                href="tel:+1234567890"
                className="flex items-center gap-2 hover:text-blue-400 transition-colors duration-300"
                aria-label="Phone Number"
              >
                <FaPhone className="text-blue-500" />
                <span>+598 097 786 020</span>
              </a>
              <a
                href="mailto:info@company.com"
                className="flex items-center gap-2 hover:text-blue-400 transition-colors duration-300"
                aria-label="Email Address"
              >
                <FaEnvelope className="text-blue-500" />
                <span>info@caminoventura.com</span>
              </a>
            </div>
          </div>

          {/* Location Information */}
          <div className="flex flex-col items-center md:items-start">
            <h3 className="text-xl font-bold mb-4" role="heading" aria-level="2">Ubicacion</h3>
            <div className="flex items-start gap-2">
              <FaMapMarkerAlt className="text-blue-500 mt-1" />
              <p className="text-white">
                Cam. Lorenzo Ventura,
                <br />
                15500 Barros Blancos
                <br />
                Departamento de  Canelones
              </p>
            </div>
          </div>

          {/* Social Media Links */}
          <div className="flex flex-col items-center md:items-start">
            <h3 className="text-xl font-bold mb-4" role="heading" aria-level="2">Conecta con nosotros</h3>
            <div className="flex gap-4">
              {socialLinks.map((social, index) => (
                <a
                  key={index}
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-2xl hover:text-blue-400 transition-transform duration-300 hover:scale-110 pointer-events-none"
                  aria-label={`Follow us on ${social.label}`}
                >
                  {social.icon}
                </a>
              ))}
            </div>
          </div>
        </div>

        {/* Copyright Section */}
        <div className="mt-12 pt-8 border-t border-white text-center">
          <p className="text-white text-sm">
            © {new Date().getFullYear()} Camino Ventura. Todos los derechos reservados.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;