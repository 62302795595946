// Import React Library
import React, { useState, useEffect } from "react";

// Import React Icons
import { FaBars, FaTimes, FaHome, FaEnvelope, FaInfoCircle } from "react-icons/fa";

// Mobile Navbar Component
const MobileNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const navLinks = [
    { title: "Inicio", icon: <FaHome />, href: "#" },
    { title: "Servicios", icon: <FaInfoCircle />, href: "#services" },
    { title: "Contact", icon: <FaEnvelope />, href: "#contact-form" },
  ];

  return (
    <nav
      className={`fixed top-0 w-full z-50 transition-all duration-300 ${
        isScrolled ? "bg-white shadow-lg" : "bg-transparent"
      }`}
      role="navigation"
      aria-label="Main navigation"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex-shrink-0">
            <svg className='navbar-mobile-image' xmlns="http://www.w3.org/2000/svg" version="1.0" width="283.000000pt" height="248.000000pt" viewBox="0 0 283.000000 248.000000" preserveAspectRatio="xMidYMid meet">
                  <defs>
                      <linearGradient id="myGradient" gradientTransform="rotate(90)">
                      <stop offset="17%" stop-color="hsla(194, 72%, 44%, 1)" />
                      <stop offset="100%" stop-color="hsla(219, 69%, 42%, 1)" />
                      </linearGradient>
                  </defs>
                  <g transform="translate(0.000000,248.000000) scale(0.100000,-0.100000)" fill="url(#myGradient)" stroke="none">
                      <path d="M612 1588 l-144 -141 119 -118 118 -118 265 259 265 259 -240 0 -240 0 -143 -141z" />
                      <path d="M1400 1464 l0 -238 145 -145 145 -146 115 115 c63 63 115 118 115 122 0 7 -500 521 -512 526 -5 2 -8 -103 -8 -234z" />
                      <path d="M2430 1650 c-25 -27 -139 -147 -254 -265 l-210 -216 115 -115 c62 -63 118 -113 124 -113 5 1 71 64 145 141 l135 139 3 239 c2 132 -1 240 -5 239 -4 0 -28 -22 -53 -49z" />
                      <path d="M315 1300 l-110 -110 110 -110 110 -110 110 110 110 110 -110 110 -110 110 -110 -110z" />
                      <path d="M585 1050 l-117 -118 143 -140 144 -141 238 -1 c130 0 237 1 237 3 0 2 -119 118 -263 259 l-264 257 -118 -119z" />
                      <path d="M1834 1008 c-57 -55 -104 -106 -104 -112 0 -6 48 -60 108 -119 l107 -107 108 108 c59 59 107 112 107 117 0 12 -202 215 -213 215 -5 0 -55 -46 -113 -102z" />
                  </g>
              </svg>
          </div>

          <button
            className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => setIsOpen(!isOpen)}
            aria-expanded={isOpen}
            aria-controls="mobile-menu"
            aria-label="Toggle navigation menu"
          >
            <span className="sr-only">{isOpen ? "Close menu" : "Open menu"}</span>
            {isOpen ? (
              <FaTimes className="block h-6 w-6 transition-transform duration-200 ease-in-out transform rotate-180" />
            ) : (
              <FaBars className="block h-6 w-6 transition-transform duration-200 ease-in-out" />
            )}
          </button>
        </div>
      </div>

      <div
        className={`${
          isOpen ? "translate-x-0 opacity-100" : "translate-x-full opacity-0"
        } transform transition-all duration-300 ease-in-out absolute top-16 right-0 w-full bg-white shadow-lg h-screen`}
        id="mobile-menu"
        role="menu"
        aria-orientation="vertical"
      >
        <div className="px-2 pt-2 pb-3 space-y-1">
          {navLinks.map((link, index) => (
            <a
              key={index}
              href={link.href}
              className="group flex items-center px-3 py-2 text-base font-medium rounded-md text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition-colors duration-200"
              role="menuitem"
              tabIndex={0}
              onClick={()=> setIsOpen(false)}
            >
              <span className="mr-3 text-gray-500 group-hover:text-gray-700 transition-colors duration-200">
                {link.icon}
              </span>
              {link.title}
            </a>
          ))}
        </div>
      </div>
    </nav>
  );
};

// Export Mobile Navbar Component
export default MobileNavbar;