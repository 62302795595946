// Import React
import { useEffect, useState } from "react";

// Import Components
import Cover from "./components/Cover";
import Navbar from "./components/Navbar";
import Services from "./components/Services";
import ContactForm from "./components/ContactForm";
import Footer from "./components/Footer";
import MobileNavbar from "./components/MobileNavbar";
import UpButton from "./components/UpButton";
import WhatsappButton from './components/WhatsappButton';

// App Component
function App() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {width > 1200 ? <Navbar /> : <MobileNavbar />}
      <Cover 
        imageCover={width > 1200 ? 'image-cover-section' : 'image-cover-section-mobile'}
        textCover={width > 1200 ? 'text-cover-section' : 'text-cover-section-mobile'}
      />
      <Services />
      <ContactForm />
      <WhatsappButton />
      <UpButton />
      <Footer />
    </>
  );
}

// Export App Component
export default App;