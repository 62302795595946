// Import React Library
import React from 'react';

// Cover Component
const Cover = ({imageCover , textCover}) => {
    return (
        <div className='cover-section'>
            <div className={textCover}>
                <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                    <span class="block xl:inline">Parque empresarial </span> 
                </h1>

                <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                    <span class="block text-primary xl:inline text-blue-900">Camino Ventura</span>
                </h1>

                <p className='mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0'>
                    En Parque Ventura nos especializamos en ofrecer soluciones integrales para empresas que buscan terrenos y galpones en alquiler.
                    Garantizamos seguridad las 24 horas del día, con un control de acceso que protege las instalaciones y brinda tranquilidad a nuestros arrendatarios.
                    Nuestros terrenos están estratégicamente ubicados para facilitar la conectividad y la eficiencia operativa de las empresas.
                    Nuestro compromiso es proporcionar un entorno seguro, funcional y profesional que impulse el éxito de tu negocio. 
                    Si buscas un espacio confiable para expandir tus operaciones, somos tu mejor opción.
                </p>

                <br />

                <div id="get-started-btn" class="rounded-md shadow">
                    <a href="#contact-form" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-background bg-primary hover:bg-border hover:text-primary md:py-4 md:text-lg md:px-10">
                        Contactanos
                    </a>
                </div>
            </div>
            
            <div className={imageCover}></div>
        </div>
    );
};

// Export Cover Component
export default Cover;