// Import React Library
import React, {useState, useEffect} from 'react';

// Import Icons
import { SlArrowUp } from "react-icons/sl";

// WhatsappButton Component
const UpButton = () => {

    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            {
                isVisible && 
                        (<div onClick={handleClick} className='up-button'>
                             <SlArrowUp className='up-icon'/>  
                        </div>)
            }
        </>   
    );
};

export default UpButton;